import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center p-8 bg-surface-0 dark:bg-surface-900 rounded" }
const _hoisted_2 = { class: "font-bold text-2xl block mb-2 mt-6" }
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = { class: "flex items-center gap-2 mt-6" }
const _hoisted_5 = {
  key: 0,
  class: "fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
}

import DynamicDialog from 'primevue/dynamicdialog';
import ConfirmDialog from 'primevue/confirmdialog';

import Toast from 'primevue/toast';
import {ref} from "vue";
import apiClient from "@/services/axios.service";
import ProgressSpinner from 'primevue/progressspinner'; // Use PrimeVue spinner


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const isLoading = ref(false);

// Request Interceptor
apiClient.interceptors.request.use(config => {
  isLoading.value = true;  // Set loading flag to true when a request starts
  return config;
}, error => {
  isLoading.value = false;
  return Promise.reject(error);
});

// Response Interceptor
apiClient.interceptors.response.use(response => {
  isLoading.value = false;  // Reset loading flag when a response is received
  return response;
}, error => {
  isLoading.value = false;
  return Promise.reject(error);
});


console.log('Version: 1.1.20')


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_unref(Toast)),
    _createVNode(_unref(DynamicDialog)),
    _createVNode(_unref(ConfirmDialog), { group: "templating" }, {
      container: _withCtx(({ message, acceptCallback, rejectCallback }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(message.header), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(message.message), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Button, {
              class: "primary-outline-button",
              severity: "secondary",
              label: "Cancel",
              outlined: "",
              onClick: rejectCallback
            }, null, 8, ["onClick"]),
            _createVNode(_component_Button, {
              class: "primary-button",
              label: "Save",
              onClick: acceptCallback
            }, null, 8, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }),
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_unref(ProgressSpinner))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})