import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import {authGuard} from '@/guards/authGuard';
import {affiliateRoleGuard} from "@/guards/affiliateRoleGuard";
import {adminRoleGuard} from "@/guards/adminRoleGuard";


const Login = () => import(/* webpackChunkName: "login" */ '@/views/_public/login.component.vue');
const Layout = () => import(/* webpackChunkName: "layout" */ '@/views/_secure/layout/layout.component.vue');
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/_secure/dashboard/dashboard.component.vue');
const AffiliateDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/_secure/affiliate/affiliate-dashboard.component.vue');
const AffiliateOverview = () => import(/* webpackChunkName: "dashboard" */ '@/views/_secure/affiliate/affiliate-overview.component.vue');
const ApplicationOverview = () => import(/* webpackChunkName: "dashboard" */ '@/views/_secure/application/application-overview.component.vue');
const Settings = () => import(/* webpackChunkName: "dashboard" */ '@/views/_secure/settings/settings.component.vue');
const Admins = () => import(/* webpackChunkName: "dashboard" */ '@/views/_secure/admin/admin-overview.component.vue');
const Payouts = () => import(/* webpackChunkName: "dashboard" */ '@/views/_secure/payout-batches/payout-batches-overview.component.vue');
const AuthToken = () => import(/* webpackChunkName: "dashboard" */ '@/views/_public/auth.component.vue');

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/auth',
        name: 'AuthToken',
        component: AuthToken,
    },
    {
        path: '/secure',
        component: Layout,
        beforeEnter: authGuard,
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                beforeEnter: affiliateRoleGuard,
                component: Dashboard,
            },
            {
                path: 'sub-affiliates/dashboard',
                name: 'SubAffiliateDashboard',
                component: AffiliateDashboard,
            },
            {
                path: 'affiliates/dashboard',
                name: 'AffiliateDashboard',
                beforeEnter: adminRoleGuard,
                component: AffiliateDashboard,
            },
            {
                path: 'affiliates/overview',
                name: 'AffiliateOverview',
                beforeEnter: adminRoleGuard,
                component: AffiliateOverview,
            },
            {
                path: 'applications/overview',
                name: 'ApplicationOverview',
                component: ApplicationOverview,
            },
            {
                path: 'settings',
                name: 'Settings',
                beforeEnter: adminRoleGuard,
                component: Settings,
            },
            {
                path: 'admins',
                name: 'Admins',
                beforeEnter: adminRoleGuard,
                component: Admins,
            },
            {
                path: 'payouts',
                name: 'Payouts',
                beforeEnter: adminRoleGuard,
                component: Payouts,
            },
        ],
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/login',
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // Always scroll to the top of the page on route navigation
        return { left: 0, top: 0 };
    }
});

export default router;
